.edit-profile {
    padding: 10px 50px 30px 20px;
    overflow-y: hidden;
    height: calc(100vh - 102px);
    .edit-label {
        position: relative;
        .cancel-button {
            position: absolute;
            top: 120px;
            display: flex;
            width: 100%;
            justify-content: space-evenly;
            .upload {
                color: #3F3D56;
            }
            button {
                display: flex;
                align-items: center;
                color: #00CDAC;
                border: none !important;
                border-radius: 4px;
                height: 25px;
                cursor: pointer;
                background: none;
                font-size: 15px;
            }
            button:hover {
                background: rgba(128, 128, 128, .2);
            }
        }
    }
    #upload {
        opacity: 0;
        position: absolute;
        z-index: -1;
        visibility: hidden;
    }
    .upload-label {
        position: absolute;
        top: 6px;
        left: 45%;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: #F4F4F5;
        box-sizing: border-box;
        height: 110px;
        width: 110px;
        img {
            height: 110px;
            width: 110px;
            border-radius: 50%;
        }
        span {
            display: flex;
        }
        .material-icons {
            color: #686565;
        }
        .placeholder {
            color: #C4C4C4;
            font-weight: normal;
            font-size: 12px;
            padding: 3px 5px 0 5px;
        }
        .loader {
            position: absolute;
            opacity: .7;
        }
        .faded {
            position: absolute;
            width: 110px;
            height: 55px;
            top: 55px;
            border-radius: 0 0 150px 150px;
            text-align: center;
            color: white;
            display: flex;
            justify-content: space-around;
            align-items: center;
            background: rgba(0, 205, 172, .8);
            font-size: 12px;
            font-weight: 600;
            color: white;
        }
    }

    .edit-wrap {
        box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1), 0px 10px 10px rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        margin-top: 60px;
        padding: 30px 0 15px 0;
        height: 90%;
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-right: 25px;
            padding-top: 70px;
        }
        .aligner {
            display: flex;
            justify-content: space-between;
            width: 70%;
            padding: 15px 0;
        }
        .email-nochange {
            position: relative;
            .small {
                position: absolute;
                right: 10px;
                padding-top: 7px;
                color: #E42606;
                font-size: 12px;
            }
        }
        input, select {
            background: #F4F4F5;
            border: 1px solid #F4F4F5;
            box-sizing: border-box;
            border-radius: 4px;
            height: 30px;
            width: 450px;
            color: rgba(0, 0, 0, 0.6);
            padding-left: 5px;
        }
        input:focus {
            outline: none !important;
            border: 1px solid #00CDAC;
        }
        label {
            color: #71717A;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            padding-right: 20px;
        }
        .footer {
            display: flex;
            border: 1px solid red;
            justify-content: space-between;
            width: 80%;
        }
    }
}