html {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

// ** landing goes here **

.landing-container {
    background-image: url("../assets/images/background.png");
    background-size: cover;
    height: 90vh;
    background-position-y: bottom;
}

.text-white {
    color: white;
}

.landing-modal {
    width: 218.56px;
    .home {
        font-size: 19px;
        font-weight: bold;
        color: #00CDAC;
        cursor: pointer;
    }

    .div-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 29px;
        margin-top: 10px;
        width: 60%;

        .border-btn {
            color: #00CDAC;
        }

        .sm-logo {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

    }
    .ul-content {
        display: block;
        justify-content: space-between;
        color: #808088;
        li {
            list-style: none;
            padding-bottom: 10px;
            margin-left: -20px;
            cursor: pointer;
        }
    }
}

.top-nav {
    height: 120px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 1024px) {
        height: 80px;
    }

    .top-links {
        width: 70%;
        margin-left: 50px;
        display: flex;
        @media screen and (max-width: 1024px) {
            margin: 0;
            ul {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-left: 10px;
            }
        }

        .mobile-menu {
            display: none;
            color: white;
            .border-btn {
                color: white;
            }
            @media screen and (max-width: 1024px) {
                display: block;
            }
        }

        .home {
            font-size: 25px;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
                font-size: 18px;
            }
            .lg-logo {
                width: 100px;
                height: 100px;
                margin-top: 50px;
                border-radius: 50%;
            }
            .sm-logo {
                width: 60px;
                height: 60px;
                margin-top: 20px;
                border-radius: 50%;
            }
        }
        li {
            display: inline;
            color: #EAEAEA;
            font-size: 15px;
            padding: 0 17px;
            cursor: pointer;
            @media screen and (max-width: 1024px) {
                padding: 0;
            }
        }
        li:hover {
            color: white;
        }
    }

    .top-buttons {
        margin-left: 20%;
        display: flex;
        margin-right: 10px;
        @media screen and (max-width: 1024px) {
            width: 40%;
            margin: 0;
            display: flex;
            justify-content: space-around;
        }
    }
}

.details-wrap {
    width: 99%;
    display: flex;
    @media screen and (max-width: 1024px) {
        width: 95%;
        flex-wrap: wrap;
    }
}

.details {
    width: 44%;
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    .details-inner {
        margin: 20px 0 0 90px;
        @media screen and (max-width: 1024px) {
            margin: 35px 0 90px 20px;
        }
    }

    h1 {
        font-size: 65px;
        @media screen and (max-width: 1024px) {
            font-size: 45px;
        }
    }
    p {
        font-size: 17px;
        @media screen and (max-width: 1024px) {
            font-size: 14px;
            padding-bottom: 35px;
        }
    }
}

.button-primary {
    background: #00CDAC;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border-radius: 9px;
    height: 35px;
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.button-primary:hover {
    background: #0b8b76
}

.button-action-inv {
    background: inherit;
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
    border: 1px solid #00CDAC;
    border-radius: 9px;
    height: 33px;
    color: #00CDAC;
    font-size: 15px;
    cursor: pointer;
}

.button-action-inv:hover {
    background: rgba(255, 117, 140, 0.2);
}

.button-clear {
    background: inherit;
    border-radius: 9px;
    height: 35px;
    border: none;
    color: white;
    font-size: 15px;
    cursor: pointer;
}

.button-white {
    background: white;
    border-radius: 9px;
    color: #00CDAC;
    height: 35px;
    border: none;
    font-size: 15px;
    cursor: pointer;
}

.btn-w-1 {
    padding: 0 50px;
    @media screen and (max-width: 1024px) {
        padding: 0 35px;
    }
}

.btn-w-2 {
    padding: 0 50px;
    @media screen and (max-width: 1024px) {
        padding: 0 15px;
    }
}

.button-clear:hover {
    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
}

.button-white:hover {
    background: #EAEAEA;
}

.screenshot {
    width: 59%;
    background-image: url("../assets/images/clients.png");
    height: 73vh;
    background-repeat: no-repeat;
    @media screen and (max-width: 1024px) {
        width: 100%;
        height: 50vh;
    }
}

// ** auth pages **
.auth-cover {
    background: linear-gradient(90deg, #00CDAC 50.05%, #60D6C8 68.6%, #8DDAD5 98.55%);
    height: 100vh;
    display: flex;
    align-items: center;

    .auth-white-cover {
        width: 70%;
        margin: 0 auto;
        background: white;
        border-radius: 8px;
        @media screen and (max-width: 1024px) {
            width: 90%;
        }

        .auth-float {
            height: 90vh;
            background: url("../assets/images/area.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position-y: bottom;
            background-size: 100% 40%;
            box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.25);
            border-radius: 7px;
            display: flex;
            @media screen and (max-width: 1024px) {
                background-size: 100% 20%;
            }

            .auth-left {
                width: 50%;
                background-image: url("../assets/images/officer.png");
                background-repeat: no-repeat;
                background-size: 400px 209px;
                background-position: 40% 35%;
                @media screen and (max-width: 1024px) {
                    display: none;
                }
            }
            
            .auth-right {
                width: 50%;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                    padding-left: 15px;
                }
                // ** top words **
                .top {
                    width: 100%;
                    h3 {
                        color: #42474A;
                        font-size: 22px;
                        line-height: 13px;
                    }
                    p {
                        font-size: 16px;
                        line-height: 0;
                        color: #42474A;
                    }
                }

                // ** Center inputs **
                form {
                    width: 100%;
                }
                .center {
                    width: 100%;

                    .flag {
                        display: flex;
                        width: 80%;

                        .input-wrap {
                            width: 100%;
                            margin-left: 10px;
                            position: relative;
                            .code-input {
                                position: absolute;
                                color: #666464;
                                background: #d4d0d0;
                                height: 25px;
                                padding: 10px 5px 0 5px;
                                border-radius: 5px 0 0 4px;
                            }
                            .phone-input {
                                width: 100%;
                                padding-left: 40px;
                            }
                            .phone-input::placeholder {
                                padding-left: 35px;
                            }
                        }
                    }

                    label {
                        display: block;
                        font-weight: bold;
                        font-size: 14px;
                        color: #71717A;
                        padding: 5px 0;
                    }

                    input {
                        background: #F4F4F5;
                        border: 1px solid #F4F4F5;
                        box-sizing: border-box;
                        border-radius: 4px;
                        height: 35px;
                        width: 80%;
                        padding-left: 10px;
                        @media screen and (max-width: 1024px) {
                            width: 90%;
                        }
                    }

                    .password-wrap {
                        width: 80%;
                        position: relative;
                        input {
                            background: #F4F4F5;
                            border: 1px solid #F4F4F5;
                            box-sizing: border-box;
                            border-radius: 4px;
                            height: 35px;
                            width: 100%;
                            padding-left: 10px;
                            @media screen and (max-width: 1024px) {
                                width: 90%;
                            }
                        }
                        .input-pass-icon {
                            color:  #666464;
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            height: 35px;
                            display: flex;
                            align-items: center;
                            padding-right: 10px;
                            span {
                                cursor: pointer;
                            }
                        }
                    }

                    .input-error {
                        background: rgba(255, 0, 0, .2);
                        color: red;
                        border: 1px solid red;
                    }

                    .input-error::placeholder {
                        color:  red;
                    }

                    .logo-error {
                        border: 1px solid red;
                    }

                    .logo-fine {
                        border: 1px solid #F4F4F5;
                    }

                    small {
                        display: block;
                        color: red;
                        font-size: 12px;
                    }

                    input:focus {
                        outline: none !important;
                        border: 1px solid #00CDAC;
                    }
                }

                // ** Bottom buttons **
                .bottom {
                    width: 100%;
                    margin-top: 8px;
                    .accept {
                        padding-bottom: 10px;
                        display: flex;
                        align-items: center;
                        input {
                            margin-right: 10px;
                            cursor: pointer;
                        }
                    }

                    .signup-login {
                        margin-top: 15px;
                    }

                    .forgot-pass {
                        padding-left: 15px;
                        span {
                            font-size: 14px;
                            color: #00CDAC;
                            border: none !important;
                            background: inherit !important;
                            font-weight: bold;
                            cursor: pointer;
                        }
                        button:hover {
                            color: #0b8b76;
                        }
                    }
                }

                .sign-padding {
                    padding-left: 10px;
                }
                // ** verify **
                .verify-middle {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    height: 90%;
                    width: 100%;
                    padding-left: 10px;
                    .welcome {
                        color:  #00CDAC;
                        font-weight: bold;
                        font-size: 22px;
                        padding-bottom: 15px;
                    }
                    .notification {
                        width: 80%;
                        margin-bottom: 10px;
                    }
                    .verify-buttons {
                        display: flex;
                        justify-content: space-between;
                        width: 80%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

.terms {
    font-size: 14px;
    color: #71717A;
    b {
        color: #00CDAC;
        cursor: pointer;
    }
    b:hover {
        color: #0b8b76;
    }
}

.terms-error {
    color:  red;
    display: block;
    line-break: auto;
}