.notification-wrap {
    position: relative;
    width: 80%;
}
.auto-margin {
    margin-bottom: 10px;
}
.hidden {
    visibility: hidden;
}
.auto-position {
    position: absolute;
    top: 50px;
}
.notification-inner {
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    .title { 
        font-weight: bold;
        font-size: 15px;
        padding: 7px 10px;
    }

    .message {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        border-radius: 4px;
        padding: 7px 10px;

    }
}

.error {
    border: 1px solid #EF4444;
    .title {
        background: #DC2626;
        color: white;
    }

    .message {
        background: #FAE2E2;
        color: #DC2626;
    }
}

.success {
    border: 1px solid #33D69F;
    .title {
        background: #70D8A0;
        color: white;
    }

    .message {
        background: #EDFBF6;
        color: #3F3D56;
    }
}

.default {
    border: 1px solid #00CDAC;
    .title {
        background: #00CDAC;
        color: white;
    }

    .message {
        background: #EDFBF6;
        color: #3F3D56;
    }
}

.notification {
    position: relative;
    margin-right: 10px;
    .success-sm {
        background: #33D69F;
    }
    
    .error-sm {
        background: #EF4444;
    }
    .box {
        position: absolute;
        left: 26%;
        color: white;
        display: flex;
        align-items: center;
        font-weight: 700;
        padding: 8px 5px;
        font-size: 14px;

        .material-icons {
            font-style: italic;
            font-size: 18px;
            padding-right: 5px;
        }
    }
}