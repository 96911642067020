*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.container {
  width: 100%;
}

@media (min-width: 320px) {
  .container {
    max-width: 320px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

#root :is(.pointer-events-none) {
  pointer-events: none;
}

#root :is(.\!visible) {
  visibility: visible !important;
}

#root :is(.visible) {
  visibility: visible;
}

#root :is(.absolute) {
  position: absolute;
}

#root :is(.relative) {
  position: relative;
}

#root :is(.inset-y-0) {
  top: 0px;
  bottom: 0px;
}

#root :is(.-bottom-2) {
  bottom: -0.5rem;
}

#root :is(.bottom-3) {
  bottom: 0.75rem;
}

#root :is(.bottom-full) {
  bottom: 100%;
}

#root :is(.left-0) {
  left: 0px;
}

#root :is(.right-0) {
  right: 0px;
}

#root :is(.top-36) {
  top: 9rem;
}

#root :is(.z-10) {
  z-index: 10;
}

#root :is(.z-20) {
  z-index: 20;
}

#root :is(.m-1) {
  margin: 0.25rem;
}

#root :is(.m-2) {
  margin: 0.5rem;
}

#root :is(.my-1) {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

#root :is(.my-12) {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

#root :is(.my-3) {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

#root :is(.mb-2) {
  margin-bottom: 0.5rem;
}

#root :is(.mb-2\.5) {
  margin-bottom: 0.625rem;
}

#root :is(.mb-3) {
  margin-bottom: 0.75rem;
}

#root :is(.ml-\[1\.2rem\]) {
  margin-left: 1.2rem;
}

#root :is(.mr-0) {
  margin-right: 0px;
}

#root :is(.mr-0\.5) {
  margin-right: 0.125rem;
}

#root :is(.mr-2) {
  margin-right: 0.5rem;
}

#root :is(.mr-3) {
  margin-right: 0.75rem;
}

#root :is(.mr-3\.5) {
  margin-right: 0.875rem;
}

#root :is(.mt-0) {
  margin-top: 0px;
}

#root :is(.mt-0\.5) {
  margin-top: 0.125rem;
}

#root :is(.mt-2) {
  margin-top: 0.5rem;
}

#root :is(.mt-2\.5) {
  margin-top: 0.625rem;
}

#root :is(.mt-\[1px\]) {
  margin-top: 1px;
}

#root :is(.block) {
  display: block;
}

#root :is(.inline-block) {
  display: inline-block;
}

#root :is(.flex) {
  display: flex;
}

#root :is(.table) {
  display: table;
}

#root :is(.grid) {
  display: grid;
}

#root :is(.hidden) {
  display: none;
}

#root :is(.h-12) {
  height: 3rem;
}

#root :is(.h-28) {
  height: 7rem;
}

#root :is(.h-4) {
  height: 1rem;
}

#root :is(.h-5) {
  height: 1.25rem;
}

#root :is(.h-6) {
  height: 1.5rem;
}

#root :is(.h-7) {
  height: 1.75rem;
}

#root :is(.h-full) {
  height: 100%;
}

#root :is(.h-max) {
  height: -webkit-max-content;
  height: max-content;
}

#root :is(.min-h-\[285px\]) {
  min-height: 285px;
}

#root :is(.w-1) {
  width: 0.25rem;
}

#root :is(.w-1\/2) {
  width: 50%;
}

#root :is(.w-12) {
  width: 3rem;
}

#root :is(.w-28) {
  width: 7rem;
}

#root :is(.w-4) {
  width: 1rem;
}

#root :is(.w-48) {
  width: 12rem;
}

#root :is(.w-5) {
  width: 1.25rem;
}

#root :is(.w-6) {
  width: 1.5rem;
}

#root :is(.w-full) {
  width: 100%;
}

#root :is(.flex-1) {
  flex: 1 1 0%;
}

#root :is(.flex-none) {
  flex: none;
}

#root :is(.translate-y-0) {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#root :is(.translate-y-4) {
  --tw-translate-y: 1rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#root :is(.rotate-45) {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

#root :is(.animate-spin) {
  animation: spin 1s linear infinite;
}

#root :is(.cursor-no-drop) {
  cursor: no-drop;
}

#root :is(.cursor-pointer) {
  cursor: pointer;
}

#root :is(.appearance-none) {
  -webkit-appearance: none;
          appearance: none;
}

#root :is(.grid-cols-2) {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

#root :is(.grid-cols-3) {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

#root :is(.grid-cols-7) {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

#root :is(.flex-col) {
  flex-direction: column;
}

#root :is(.flex-wrap) {
  flex-wrap: wrap;
}

#root :is(.items-end) {
  align-items: flex-end;
}

#root :is(.items-center) {
  align-items: center;
}

#root :is(.items-baseline) {
  align-items: baseline;
}

#root :is(.items-stretch) {
  align-items: stretch;
}

#root :is(.justify-start) {
  justify-content: flex-start;
}

#root :is(.justify-end) {
  justify-content: flex-end;
}

#root :is(.justify-center) {
  justify-content: center;
}

#root :is(.justify-between) {
  justify-content: space-between;
}

#root :is(.gap-2) {
  gap: 0.5rem;
}

#root :is(.gap-4) {
  gap: 1rem;
}

#root :is(.gap-6) {
  gap: 1.5rem;
}

#root :is(.gap-y-0) {
  row-gap: 0px;
}

#root :is(.gap-y-0\.5) {
  row-gap: 0.125rem;
}

#root :is(.space-x-1 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

#root :is(.space-x-1\.5 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}

#root :is(.space-x-3 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

#root :is(.space-y-4 > :not([hidden]) ~ :not([hidden])) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

#root :is(.overflow-x-hidden) {
  overflow-x: hidden;
}

#root :is(.whitespace-nowrap) {
  white-space: nowrap;
}

#root :is(.break-words) {
  overflow-wrap: break-word;
}

#root :is(.rounded) {
  border-radius: 0.25rem;
}

#root :is(.rounded-2xl) {
  border-radius: 1rem;
}

#root :is(.rounded-full) {
  border-radius: 9999px;
}

#root :is(.rounded-lg) {
  border-radius: 0.5rem;
}

#root :is(.rounded-md) {
  border-radius: 0.375rem;
}

#root :is(.rounded-xl) {
  border-radius: 0.75rem;
}

#root :is(.rounded-l-full) {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

#root :is(.rounded-r-full) {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}

#root :is(.border) {
  border-width: 1px;
}

#root :is(.border-0) {
  border-width: 0px;
}

#root :is(.border-b) {
  border-bottom-width: 1px;
}

#root :is(.border-l) {
  border-left-width: 1px;
}

#root :is(.border-r) {
  border-right-width: 1px;
}

#root :is(.border-t) {
  border-top-width: 1px;
}

#root :is(.border-dashed) {
  border-style: dashed;
}

#root :is(.border-\[\#25282C33\]\/20) {
  border-color: rgb(37 40 44 / 0.2);
}

#root :is(.border-\[\#D9D9D9\]) {
  --tw-border-opacity: 1;
  border-color: rgb(217 217 217 / var(--tw-border-opacity));
}

#root :is(.border-amber-500) {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

#root :is(.border-blue-500) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

#root :is(.border-cyan-500) {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

#root :is(.border-emerald-500) {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

#root :is(.border-fuchsia-500) {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

#root :is(.border-gray-300) {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity));
}

#root :is(.border-green-500) {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

#root :is(.border-indigo-500) {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

#root :is(.border-lime-500) {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

#root :is(.border-orange-500) {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

#root :is(.border-pink-500) {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

#root :is(.border-purple-500) {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

#root :is(.border-red-500) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

#root :is(.border-rose-500) {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

#root :is(.border-sky-500) {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

#root :is(.border-teal-500) {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

#root :is(.border-violet-500) {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

#root :is(.border-yellow-500) {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

#root :is(.bg-\[\#00CDAC33\]\/20) {
  background-color: rgb(0 205 172 / 0.2);
}

#root :is(.bg-\[\#00CDAC\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 205 172 / var(--tw-bg-opacity));
}

#root :is(.bg-\[\#F7F7F7\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(247 247 247 / var(--tw-bg-opacity));
}

#root :is(.bg-amber-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity));
}

#root :is(.bg-amber-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

#root :is(.bg-amber-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

#root :is(.bg-blue-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
}

#root :is(.bg-blue-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

#root :is(.bg-blue-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

#root :is(.bg-cyan-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity));
}

#root :is(.bg-cyan-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity));
}

#root :is(.bg-cyan-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity));
}

#root :is(.bg-emerald-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity));
}

#root :is(.bg-emerald-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity));
}

#root :is(.bg-emerald-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity));
}

#root :is(.bg-fuchsia-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity));
}

#root :is(.bg-fuchsia-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
}

#root :is(.bg-fuchsia-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity));
}

#root :is(.bg-gray-50) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

#root :is(.bg-green-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

#root :is(.bg-green-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

#root :is(.bg-green-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

#root :is(.bg-indigo-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity));
}

#root :is(.bg-indigo-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity));
}

#root :is(.bg-indigo-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

#root :is(.bg-lime-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity));
}

#root :is(.bg-lime-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity));
}

#root :is(.bg-lime-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity));
}

#root :is(.bg-orange-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity));
}

#root :is(.bg-orange-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity));
}

#root :is(.bg-orange-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity));
}

#root :is(.bg-pink-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity));
}

#root :is(.bg-pink-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity));
}

#root :is(.bg-pink-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity));
}

#root :is(.bg-primary) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 205 172 / var(--tw-bg-opacity));
}

#root :is(.bg-purple-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity));
}

#root :is(.bg-purple-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity));
}

#root :is(.bg-purple-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity));
}

#root :is(.bg-red-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

#root :is(.bg-red-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

#root :is(.bg-red-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

#root :is(.bg-rose-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity));
}

#root :is(.bg-rose-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity));
}

#root :is(.bg-rose-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity));
}

#root :is(.bg-sky-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity));
}

#root :is(.bg-sky-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
}

#root :is(.bg-sky-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity));
}

#root :is(.bg-teal-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity));
}

#root :is(.bg-teal-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity));
}

#root :is(.bg-teal-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity));
}

#root :is(.bg-violet-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity));
}

#root :is(.bg-violet-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity));
}

#root :is(.bg-violet-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity));
}

#root :is(.bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

#root :is(.bg-yellow-100) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

#root :is(.bg-yellow-200) {
  --tw-bg-opacity: 1;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity));
}

#root :is(.bg-yellow-500) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity));
}

#root :is(.p-0) {
  padding: 0px;
}

#root :is(.p-0\.5) {
  padding: 0.125rem;
}

#root :is(.p-2) {
  padding: 0.5rem;
}

#root :is(.p-4) {
  padding: 1rem;
}

#root :is(.p-\[0\.45rem\]) {
  padding: 0.45rem;
}

#root :is(.px-0) {
  padding-left: 0px;
  padding-right: 0px;
}

#root :is(.px-0\.5) {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

#root :is(.px-1) {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

#root :is(.px-10) {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

#root :is(.px-12) {
  padding-left: 3rem;
  padding-right: 3rem;
}

#root :is(.px-2) {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

#root :is(.px-3) {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

#root :is(.px-4) {
  padding-left: 1rem;
  padding-right: 1rem;
}

#root :is(.px-6) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#root :is(.px-9) {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

#root :is(.py-0) {
  padding-top: 0px;
  padding-bottom: 0px;
}

#root :is(.py-0\.5) {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

#root :is(.py-1) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

#root :is(.py-1\.5) {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

#root :is(.py-2) {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

#root :is(.py-2\.5) {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

#root :is(.py-3) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

#root :is(.py-8) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#root :is(.py-\[0\.55rem\]) {
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
}

#root :is(.pb-1) {
  padding-bottom: 0.25rem;
}

#root :is(.pb-2) {
  padding-bottom: 0.5rem;
}

#root :is(.pb-2\.5) {
  padding-bottom: 0.625rem;
}

#root :is(.pb-5) {
  padding-bottom: 1.25rem;
}

#root :is(.pl-4) {
  padding-left: 1rem;
}

#root :is(.pr-1) {
  padding-right: 0.25rem;
}

#root :is(.pr-14) {
  padding-right: 3.5rem;
}

#root :is(.pr-2) {
  padding-right: 0.5rem;
}

#root :is(.pt-3) {
  padding-top: 0.75rem;
}

#root :is(.pt-36) {
  padding-top: 9rem;
}

#root :is(.pt-6) {
  padding-top: 1.5rem;
}

#root :is(.text-center) {
  text-align: center;
}

#root :is(.align-bottom) {
  vertical-align: bottom;
}

#root :is(.font-poppins) {
  font-family: Poppins, sans-serif;
}

#root :is(.text-2xl) {
  font-size: 1.5rem;
  line-height: 2rem;
}

#root :is(.text-4xl) {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

#root :is(.text-\[10px\]) {
  font-size: 10px;
}

#root :is(.text-base) {
  font-size: 1rem;
  line-height: 1.5rem;
}

#root :is(.text-lg) {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

#root :is(.text-sm) {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

#root :is(.text-xl) {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

#root :is(.font-bold) {
  font-weight: 700;
}

#root :is(.font-light) {
  font-weight: 300;
}

#root :is(.font-medium) {
  font-weight: 500;
}

#root :is(.font-normal) {
  font-weight: 400;
}

#root :is(.font-semibold) {
  font-weight: 600;
}

#root :is(.uppercase) {
  text-transform: uppercase;
}

#root :is(.capitalize) {
  text-transform: capitalize;
}

#root :is(.ordinal) {
  --tw-ordinal: ordinal;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction);
}

#root :is(.leading-tight) {
  line-height: 1.25;
}

#root :is(.tracking-wide) {
  letter-spacing: 0.025em;
}

#root :is(.text-\[\#25282C\]) {
  --tw-text-opacity: 1;
  color: rgb(37 40 44 / var(--tw-text-opacity));
}

#root :is(.text-\[\#264653\]) {
  --tw-text-opacity: 1;
  color: rgb(38 70 83 / var(--tw-text-opacity));
}

#root :is(.text-\[\#2A9D8F\]) {
  --tw-text-opacity: 1;
  color: rgb(42 157 143 / var(--tw-text-opacity));
}

#root :is(.text-\[\#66666699\]\/60) {
  color: rgb(102 102 102 / 0.6);
}

#root :is(.text-\[\#666666\]\/60) {
  color: rgb(102 102 102 / 0.6);
}

#root :is(.text-amber-500) {
  --tw-text-opacity: 1;
  color: rgb(245 158 11 / var(--tw-text-opacity));
}

#root :is(.text-amber-600) {
  --tw-text-opacity: 1;
  color: rgb(217 119 6 / var(--tw-text-opacity));
}

#root :is(.text-blue-500) {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

#root :is(.text-blue-600) {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

#root :is(.text-cyan-500) {
  --tw-text-opacity: 1;
  color: rgb(6 182 212 / var(--tw-text-opacity));
}

#root :is(.text-cyan-600) {
  --tw-text-opacity: 1;
  color: rgb(8 145 178 / var(--tw-text-opacity));
}

#root :is(.text-emerald-500) {
  --tw-text-opacity: 1;
  color: rgb(16 185 129 / var(--tw-text-opacity));
}

#root :is(.text-emerald-600) {
  --tw-text-opacity: 1;
  color: rgb(5 150 105 / var(--tw-text-opacity));
}

#root :is(.text-fuchsia-500) {
  --tw-text-opacity: 1;
  color: rgb(217 70 239 / var(--tw-text-opacity));
}

#root :is(.text-fuchsia-600) {
  --tw-text-opacity: 1;
  color: rgb(192 38 211 / var(--tw-text-opacity));
}

#root :is(.text-gray-400) {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

#root :is(.text-gray-500) {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

#root :is(.text-gray-700) {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

#root :is(.text-green-500) {
  --tw-text-opacity: 1;
  color: rgb(34 197 94 / var(--tw-text-opacity));
}

#root :is(.text-green-600) {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

#root :is(.text-indigo-500) {
  --tw-text-opacity: 1;
  color: rgb(99 102 241 / var(--tw-text-opacity));
}

#root :is(.text-indigo-600) {
  --tw-text-opacity: 1;
  color: rgb(79 70 229 / var(--tw-text-opacity));
}

#root :is(.text-lime-500) {
  --tw-text-opacity: 1;
  color: rgb(132 204 22 / var(--tw-text-opacity));
}

#root :is(.text-lime-600) {
  --tw-text-opacity: 1;
  color: rgb(101 163 13 / var(--tw-text-opacity));
}

#root :is(.text-orange-500) {
  --tw-text-opacity: 1;
  color: rgb(249 115 22 / var(--tw-text-opacity));
}

#root :is(.text-orange-600) {
  --tw-text-opacity: 1;
  color: rgb(234 88 12 / var(--tw-text-opacity));
}

#root :is(.text-pink-500) {
  --tw-text-opacity: 1;
  color: rgb(236 72 153 / var(--tw-text-opacity));
}

#root :is(.text-pink-600) {
  --tw-text-opacity: 1;
  color: rgb(219 39 119 / var(--tw-text-opacity));
}

#root :is(.text-primary) {
  --tw-text-opacity: 1;
  color: rgb(0 205 172 / var(--tw-text-opacity));
}

#root :is(.text-purple-500) {
  --tw-text-opacity: 1;
  color: rgb(168 85 247 / var(--tw-text-opacity));
}

#root :is(.text-purple-600) {
  --tw-text-opacity: 1;
  color: rgb(147 51 234 / var(--tw-text-opacity));
}

#root :is(.text-red-500) {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

#root :is(.text-red-600) {
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

#root :is(.text-rose-500) {
  --tw-text-opacity: 1;
  color: rgb(244 63 94 / var(--tw-text-opacity));
}

#root :is(.text-rose-600) {
  --tw-text-opacity: 1;
  color: rgb(225 29 72 / var(--tw-text-opacity));
}

#root :is(.text-sky-500) {
  --tw-text-opacity: 1;
  color: rgb(14 165 233 / var(--tw-text-opacity));
}

#root :is(.text-sky-600) {
  --tw-text-opacity: 1;
  color: rgb(2 132 199 / var(--tw-text-opacity));
}

#root :is(.text-teal-500) {
  --tw-text-opacity: 1;
  color: rgb(20 184 166 / var(--tw-text-opacity));
}

#root :is(.text-teal-600) {
  --tw-text-opacity: 1;
  color: rgb(13 148 136 / var(--tw-text-opacity));
}

#root :is(.text-violet-500) {
  --tw-text-opacity: 1;
  color: rgb(139 92 246 / var(--tw-text-opacity));
}

#root :is(.text-violet-600) {
  --tw-text-opacity: 1;
  color: rgb(124 58 237 / var(--tw-text-opacity));
}

#root :is(.text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

#root :is(.text-yellow-500) {
  --tw-text-opacity: 1;
  color: rgb(234 179 8 / var(--tw-text-opacity));
}

#root :is(.text-yellow-600) {
  --tw-text-opacity: 1;
  color: rgb(202 138 4 / var(--tw-text-opacity));
}

#root :is(.line-through) {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

#root :is(.placeholder-gray-400)::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity));
}

#root :is(.opacity-0) {
  opacity: 0;
}

#root :is(.shadow-lg) {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#root :is(.shadow-sm) {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

#root :is(.ring) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#root :is(.blur) {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#root :is(.drop-shadow-xl) {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#root :is(.filter) {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#root :is(.transition) {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#root :is(.transition-all) {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#root :is(.delay-150) {
  transition-delay: 150ms;
}

#root :is(.duration-300) {
  transition-duration: 300ms;
}

#root :is(.duration-500) {
  transition-duration: 500ms;
}

#root :is(.ease-in-out) {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

#root :is(.ease-out) {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.react-tel-input {
  width: 100% !important;
  font-family: "Poppins", sans-serif !important;
}

.react-tel-input .form-control {
  font-size: 1.25rem !important;
  font-weight: 600 !important;
  color: #455A64 !important;
}

.react-tel-input .special-label{
  display: none !important;
}

#root :is(.placeholder\:text-base)::placeholder {
  font-size: 1rem;
  line-height: 1.5rem;
}

#root :is(.placeholder\:capitalize)::placeholder {
  text-transform: capitalize;
}

#root :is(.hover\:bg-amber-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-blue-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-cyan-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-emerald-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-fuchsia-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-gray-100:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-gray-50:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-green-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-indigo-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-lime-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-orange-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-pink-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-purple-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-red-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-rose-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-sky-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-teal-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-violet-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity));
}

#root :is(.hover\:bg-yellow-600:hover) {
  --tw-bg-opacity: 1;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity));
}

#root :is(.hover\:text-amber-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(180 83 9 / var(--tw-text-opacity));
}

#root :is(.hover\:text-blue-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

#root :is(.hover\:text-cyan-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(14 116 144 / var(--tw-text-opacity));
}

#root :is(.hover\:text-emerald-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(4 120 87 / var(--tw-text-opacity));
}

#root :is(.hover\:text-fuchsia-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(162 28 175 / var(--tw-text-opacity));
}

#root :is(.hover\:text-gray-900:hover) {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity));
}

#root :is(.hover\:text-green-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(21 128 61 / var(--tw-text-opacity));
}

#root :is(.hover\:text-indigo-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

#root :is(.hover\:text-lime-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(77 124 15 / var(--tw-text-opacity));
}

#root :is(.hover\:text-orange-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(194 65 12 / var(--tw-text-opacity));
}

#root :is(.hover\:text-pink-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(190 24 93 / var(--tw-text-opacity));
}

#root :is(.hover\:text-purple-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(126 34 206 / var(--tw-text-opacity));
}

#root :is(.hover\:text-red-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

#root :is(.hover\:text-rose-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(190 18 60 / var(--tw-text-opacity));
}

#root :is(.hover\:text-sky-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(3 105 161 / var(--tw-text-opacity));
}

#root :is(.hover\:text-teal-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(15 118 110 / var(--tw-text-opacity));
}

#root :is(.hover\:text-violet-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(109 40 217 / var(--tw-text-opacity));
}

#root :is(.hover\:text-yellow-700:hover) {
  --tw-text-opacity: 1;
  color: rgb(161 98 7 / var(--tw-text-opacity));
}

#root :is(.hover\:drop-shadow-xl:hover) {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

#root :is(.focus\:border-0:focus) {
  border-width: 0px;
}

#root :is(.focus\:border-b-2:focus) {
  border-bottom-width: 2px;
}

#root :is(.focus\:border-amber-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(245 158 11 / var(--tw-border-opacity));
}

#root :is(.focus\:border-blue-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

#root :is(.focus\:border-cyan-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(6 182 212 / var(--tw-border-opacity));
}

#root :is(.focus\:border-emerald-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(16 185 129 / var(--tw-border-opacity));
}

#root :is(.focus\:border-fuchsia-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(217 70 239 / var(--tw-border-opacity));
}

#root :is(.focus\:border-green-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(34 197 94 / var(--tw-border-opacity));
}

#root :is(.focus\:border-indigo-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
}

#root :is(.focus\:border-lime-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(132 204 22 / var(--tw-border-opacity));
}

#root :is(.focus\:border-orange-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(249 115 22 / var(--tw-border-opacity));
}

#root :is(.focus\:border-pink-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(236 72 153 / var(--tw-border-opacity));
}

#root :is(.focus\:border-purple-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(168 85 247 / var(--tw-border-opacity));
}

#root :is(.focus\:border-red-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

#root :is(.focus\:border-rose-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(244 63 94 / var(--tw-border-opacity));
}

#root :is(.focus\:border-sky-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(14 165 233 / var(--tw-border-opacity));
}

#root :is(.focus\:border-teal-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(20 184 166 / var(--tw-border-opacity));
}

#root :is(.focus\:border-violet-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(139 92 246 / var(--tw-border-opacity));
}

#root :is(.focus\:border-yellow-500:focus) {
  --tw-border-opacity: 1;
  border-color: rgb(234 179 8 / var(--tw-border-opacity));
}

#root :is(.focus\:bg-amber-100\/50:focus) {
  background-color: rgb(254 243 199 / 0.5);
}

#root :is(.focus\:bg-blue-100\/50:focus) {
  background-color: rgb(219 234 254 / 0.5);
}

#root :is(.focus\:bg-cyan-100\/50:focus) {
  background-color: rgb(207 250 254 / 0.5);
}

#root :is(.focus\:bg-emerald-100\/50:focus) {
  background-color: rgb(209 250 229 / 0.5);
}

#root :is(.focus\:bg-fuchsia-100\/50:focus) {
  background-color: rgb(250 232 255 / 0.5);
}

#root :is(.focus\:bg-green-100\/50:focus) {
  background-color: rgb(220 252 231 / 0.5);
}

#root :is(.focus\:bg-indigo-100\/50:focus) {
  background-color: rgb(224 231 255 / 0.5);
}

#root :is(.focus\:bg-lime-100\/50:focus) {
  background-color: rgb(236 252 203 / 0.5);
}

#root :is(.focus\:bg-orange-100\/50:focus) {
  background-color: rgb(255 237 213 / 0.5);
}

#root :is(.focus\:bg-pink-100\/50:focus) {
  background-color: rgb(252 231 243 / 0.5);
}

#root :is(.focus\:bg-purple-100\/50:focus) {
  background-color: rgb(243 232 255 / 0.5);
}

#root :is(.focus\:bg-red-100\/50:focus) {
  background-color: rgb(254 226 226 / 0.5);
}

#root :is(.focus\:bg-rose-100\/50:focus) {
  background-color: rgb(255 228 230 / 0.5);
}

#root :is(.focus\:bg-sky-100\/50:focus) {
  background-color: rgb(224 242 254 / 0.5);
}

#root :is(.focus\:bg-teal-100\/50:focus) {
  background-color: rgb(204 251 241 / 0.5);
}

#root :is(.focus\:bg-violet-100\/50:focus) {
  background-color: rgb(237 233 254 / 0.5);
}

#root :is(.focus\:bg-yellow-100\/50:focus) {
  background-color: rgb(254 249 195 / 0.5);
}

#root :is(.focus\:outline-none:focus) {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

#root :is(.focus\:ring:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#root :is(.focus\:ring-0:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#root :is(.focus\:ring-1:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#root :is(.focus\:ring-2:focus) {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

#root :is(.focus\:ring-amber-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-amber-500\/20:focus) {
  --tw-ring-color: rgb(245 158 11 / 0.2);
}

#root :is(.focus\:ring-amber-500\/50:focus) {
  --tw-ring-color: rgb(245 158 11 / 0.5);
}

#root :is(.focus\:ring-blue-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-blue-500\/20:focus) {
  --tw-ring-color: rgb(59 130 246 / 0.2);
}

#root :is(.focus\:ring-blue-500\/50:focus) {
  --tw-ring-color: rgb(59 130 246 / 0.5);
}

#root :is(.focus\:ring-cyan-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-cyan-500\/20:focus) {
  --tw-ring-color: rgb(6 182 212 / 0.2);
}

#root :is(.focus\:ring-cyan-500\/50:focus) {
  --tw-ring-color: rgb(6 182 212 / 0.5);
}

#root :is(.focus\:ring-emerald-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-emerald-500\/20:focus) {
  --tw-ring-color: rgb(16 185 129 / 0.2);
}

#root :is(.focus\:ring-emerald-500\/50:focus) {
  --tw-ring-color: rgb(16 185 129 / 0.5);
}

#root :is(.focus\:ring-fuchsia-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-fuchsia-500\/20:focus) {
  --tw-ring-color: rgb(217 70 239 / 0.2);
}

#root :is(.focus\:ring-fuchsia-500\/50:focus) {
  --tw-ring-color: rgb(217 70 239 / 0.5);
}

#root :is(.focus\:ring-green-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-green-500\/20:focus) {
  --tw-ring-color: rgb(34 197 94 / 0.2);
}

#root :is(.focus\:ring-green-500\/50:focus) {
  --tw-ring-color: rgb(34 197 94 / 0.5);
}

#root :is(.focus\:ring-indigo-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-indigo-500\/20:focus) {
  --tw-ring-color: rgb(99 102 241 / 0.2);
}

#root :is(.focus\:ring-indigo-500\/50:focus) {
  --tw-ring-color: rgb(99 102 241 / 0.5);
}

#root :is(.focus\:ring-lime-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-lime-500\/20:focus) {
  --tw-ring-color: rgb(132 204 22 / 0.2);
}

#root :is(.focus\:ring-lime-500\/50:focus) {
  --tw-ring-color: rgb(132 204 22 / 0.5);
}

#root :is(.focus\:ring-orange-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-orange-500\/20:focus) {
  --tw-ring-color: rgb(249 115 22 / 0.2);
}

#root :is(.focus\:ring-orange-500\/50:focus) {
  --tw-ring-color: rgb(249 115 22 / 0.5);
}

#root :is(.focus\:ring-pink-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-pink-500\/20:focus) {
  --tw-ring-color: rgb(236 72 153 / 0.2);
}

#root :is(.focus\:ring-pink-500\/50:focus) {
  --tw-ring-color: rgb(236 72 153 / 0.5);
}

#root :is(.focus\:ring-purple-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-purple-500\/20:focus) {
  --tw-ring-color: rgb(168 85 247 / 0.2);
}

#root :is(.focus\:ring-purple-500\/50:focus) {
  --tw-ring-color: rgb(168 85 247 / 0.5);
}

#root :is(.focus\:ring-red-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-red-500\/20:focus) {
  --tw-ring-color: rgb(239 68 68 / 0.2);
}

#root :is(.focus\:ring-red-500\/50:focus) {
  --tw-ring-color: rgb(239 68 68 / 0.5);
}

#root :is(.focus\:ring-rose-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-rose-500\/20:focus) {
  --tw-ring-color: rgb(244 63 94 / 0.2);
}

#root :is(.focus\:ring-rose-500\/50:focus) {
  --tw-ring-color: rgb(244 63 94 / 0.5);
}

#root :is(.focus\:ring-sky-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-sky-500\/20:focus) {
  --tw-ring-color: rgb(14 165 233 / 0.2);
}

#root :is(.focus\:ring-sky-500\/50:focus) {
  --tw-ring-color: rgb(14 165 233 / 0.5);
}

#root :is(.focus\:ring-teal-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-teal-500\/20:focus) {
  --tw-ring-color: rgb(20 184 166 / 0.2);
}

#root :is(.focus\:ring-teal-500\/50:focus) {
  --tw-ring-color: rgb(20 184 166 / 0.5);
}

#root :is(.focus\:ring-violet-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-violet-500\/20:focus) {
  --tw-ring-color: rgb(139 92 246 / 0.2);
}

#root :is(.focus\:ring-violet-500\/50:focus) {
  --tw-ring-color: rgb(139 92 246 / 0.5);
}

#root :is(.focus\:ring-yellow-500:focus) {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity));
}

#root :is(.focus\:ring-yellow-500\/20:focus) {
  --tw-ring-color: rgb(234 179 8 / 0.2);
}

#root :is(.focus\:ring-yellow-500\/50:focus) {
  --tw-ring-color: rgb(234 179 8 / 0.5);
}

#root :is(.focus\:ring-offset-2:focus) {
  --tw-ring-offset-width: 2px;
}

#root :is(.disabled\:cursor-not-allowed:disabled) {
  cursor: not-allowed;
}

#root :is(.disabled\:bg-primary\/30:disabled) {
  background-color: rgb(0 205 172 / 0.3);
}

#root :is(.disabled\:opacity-40:disabled) {
  opacity: 0.4;
}

@media (min-width: 320px) {
  #root :is(.xs\:flex) {
    display: flex;
  }

  #root :is(.xs\:hidden) {
    display: none;
  }

  #root :is(.xs\:h-full) {
    height: 100%;
  }

  #root :is(.xs\:w-\[300px\]) {
    width: 300px;
  }

  #root :is(.xs\:w-full) {
    width: 100%;
  }

  #root :is(.xs\:w-max) {
    width: -webkit-max-content;
    width: max-content;
  }

  #root :is(.xs\:grid-cols-1) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  #root :is(.xs\:justify-start) {
    justify-content: flex-start;
  }

  #root :is(.xs\:justify-between) {
    justify-content: space-between;
  }

  #root :is(.xs\:px-2) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  #root :is(.xs\:px-4) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #root :is(.xs\:py-10) {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  #root :is(.xs\:py-3) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  #root :is(.xs\:pt-16) {
    padding-top: 4rem;
  }

  #root :is(.xs\:pt-8) {
    padding-top: 2rem;
  }

  #root :is(.xs\:text-lg) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  #root :is(.xs\:text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 640px) {
  #root :is(.sm\:px-2) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  #root :is(.sm\:text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #root :is(.sm\:leading-6) {
    line-height: 1.5rem;
  }
}

@media (min-width: 768px) {
  #root :is(.md\:bottom-0) {
    bottom: 0px;
  }

  #root :is(.md\:block) {
    display: block;
  }

  #root :is(.md\:grid) {
    display: grid;
  }

  #root :is(.md\:hidden) {
    display: none;
  }

  #root :is(.md\:h-screen) {
    height: 100vh;
  }

  #root :is(.md\:w-1\/3) {
    width: 33.333333%;
  }

  #root :is(.md\:w-96) {
    width: 24rem;
  }

  #root :is(.md\:w-\[296px\]) {
    width: 296px;
  }

  #root :is(.md\:w-auto) {
    width: auto;
  }

  #root :is(.md\:w-max) {
    width: -webkit-max-content;
    width: max-content;
  }

  #root :is(.md\:w-screen) {
    width: 100vw;
  }

  #root :is(.md\:min-w-\[296px\]) {
    min-width: 296px;
  }

  #root :is(.md\:grid-cols-2) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  #root :is(.md\:flex-row) {
    flex-direction: row;
  }

  #root :is(.md\:justify-start) {
    justify-content: flex-start;
  }

  #root :is(.md\:space-x-1 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }

  #root :is(.md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }

  #root :is(.md\:space-y-0 > :not([hidden]) ~ :not([hidden])) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  #root :is(.md\:overflow-hidden) {
    overflow: hidden;
  }

  #root :is(.md\:border-b) {
    border-bottom-width: 1px;
  }

  #root :is(.md\:px-12) {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #root :is(.md\:px-16) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  #root :is(.md\:py-3) {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  #root :is(.md\:py-4) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  #root :is(.md\:pl-1) {
    padding-left: 0.25rem;
  }

  #root :is(.md\:pl-2) {
    padding-left: 0.5rem;
  }

  #root :is(.md\:pt-0) {
    padding-top: 0px;
  }

  #root :is(.md\:pt-28) {
    padding-top: 7rem;
  }

  #root :is(.md\:pt-36) {
    padding-top: 9rem;
  }

  #root :is(.md\:text-base) {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  #root :is(.md\:text-xl) {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

@media (min-width: 1024px) {
  #root :is(.lg\:bottom-3) {
    bottom: 0.75rem;
  }

  #root :is(.lg\:mb-0) {
    margin-bottom: 0px;
  }

  #root :is(.lg\:h-10) {
    height: 2.5rem;
  }

  #root :is(.lg\:w-10) {
    width: 2.5rem;
  }

  #root :is(.lg\:w-auto) {
    width: auto;
  }

  #root :is(.lg\:flex-row) {
    flex-direction: row;
  }

  #root :is(.lg\:flex-col) {
    flex-direction: column;
  }

  #root :is(.lg\:border-b-0) {
    border-bottom-width: 0px;
  }

  #root :is(.lg\:border-r) {
    border-right-width: 1px;
  }

  #root :is(.lg\:pb-0) {
    padding-bottom: 0px;
  }

  #root :is(.lg\:pr-1) {
    padding-right: 0.25rem;
  }

  #root :is(.lg\:text-xs) {
    font-size: 0.75rem;
    line-height: 1rem;
  }
}

@media (min-width: 1280px) {
  #root :is(.xl\:px-16) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  #root :is(.xl\:pt-36) {
    padding-top: 9rem;
  }
}

@media (min-width: 1536px) {
  #root :is(.\32xl\:text-sm) {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

@media (prefers-color-scheme: dark) {
  #root :is(.dark\:border-gray-700) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }

  #root :is(.dark\:border-slate-600) {
    --tw-border-opacity: 1;
    border-color: rgb(71 85 105 / var(--tw-border-opacity));
  }

  #root :is(.dark\:bg-slate-800) {
    --tw-bg-opacity: 1;
    background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  }

  #root :is(.dark\:bg-white\/10) {
    background-color: rgb(255 255 255 / 0.1);
  }

  #root :is(.dark\:bg-white\/5) {
    background-color: rgb(255 255 255 / 0.05);
  }

  #root :is(.dark\:text-amber-400) {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-blue-400) {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-cyan-400) {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-emerald-400) {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-fuchsia-400) {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-gray-700) {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-green-400) {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-indigo-400) {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-lime-400) {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-orange-400) {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-pink-400) {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-purple-400) {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-red-400) {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-rose-400) {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-sky-400) {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-teal-400) {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-violet-400) {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-white) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }

  #root :is(.dark\:text-white\/70) {
    color: rgb(255 255 255 / 0.7);
  }

  #root :is(.dark\:text-white\/80) {
    color: rgb(255 255 255 / 0.8);
  }

  #root :is(.dark\:text-yellow-400) {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:bg-white\/10:hover) {
    background-color: rgb(255 255 255 / 0.1);
  }

  #root :is(.dark\:hover\:text-amber-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 191 36 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-blue-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(96 165 250 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-cyan-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(34 211 238 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-emerald-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(52 211 153 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-fuchsia-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(232 121 249 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-green-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(74 222 128 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-indigo-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(129 140 248 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-lime-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(163 230 53 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-orange-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 146 60 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-pink-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(244 114 182 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-purple-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(192 132 252 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-red-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(248 113 113 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-rose-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(251 113 133 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-sky-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(56 189 248 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-teal-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(45 212 191 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-violet-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(167 139 250 / var(--tw-text-opacity));
  }

  #root :is(.dark\:hover\:text-yellow-400:hover) {
    --tw-text-opacity: 1;
    color: rgb(250 204 21 / var(--tw-text-opacity));
  }

  #root :is(.dark\:focus\:bg-white\/10:focus) {
    background-color: rgb(255 255 255 / 0.1);
  }
}