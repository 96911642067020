.loading-wrap {
    background: rgba(182, 219, 213, 0.6);
    border-radius: 0 8px 8px 0;
    height: 90vh;
    width: 91.6%;
    position: absolute;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .spinner {
        img {
            width: 120px;
            height: 120px;
        }
    }
}

.modal-loader {
    position: absolute;
    width: 85%;
    height: 90%;
    background: rgba(255, 255, 255, .6);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .modal-spinner {
        img {
            width: 80px;
            height: 80px;
        }
    }
}

.verify-loader {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0 20px 0;
    .verify-spinner {
        img {
            width: 80px;
            height: 80px;
        }
    }
} 

.dash-loader {
    position: absolute;
    width: 100%;
    height: 98%;
    background: rgba(255, 255, 255, .7);
    display: flex;
    justify-content: space-around;
    align-items: center;
    .dash-spinner {
        img {
            width: 90px;
            height: 90px;
        }
        .image-loader {
            width: 200px;
            height: 200px;
        }
    }
}

.small-loader {
    position: absolute;
    width: 100%;
    background: rgba(255, 255, 255, .8);
    display: flex;
    justify-content: space-around;
    .small-spinner {
        img {
            width: 50px;
            height: 50px;
        }
    }
}