@import './defaults.scss';

.statTabs {

       .tabs{
              width: 100%;
              position: relative;
              display: flex;
              justify-content: space-between;
              // justify-items: ;
              align-items: flex-start;
              margin-top: 1rem;
       }
       
  .stat-tabs{
       position: relative;
       width: 18.75rem;
       height: 9.125rem;
       border-radius: .5rem;
       border-radius: 1px gray solid;
       background-color: white;
       margin: 1rem;
       overflow: hidden;

      .stat-icon{
       width:6rem;
       height: 6rem;
       background-color: $primary-color;
       position: absolute;
       display: flex;
       justify-content: center;
       align-items: center;
       top: -1rem;
       right:-20px;
       border-top-left-radius: 100%;
       border-bottom-left-radius: 100%;
       border-bottom-right-radius: 100%;

       .icon{
              font-size: 2.5rem;
              color: white;
       }

       }

       .stat-info{
              position: absolute;
              bottom: 0px;
              padding: 10px 20px;
              .stat-amount {
                   font-size: 1.5rem;  
                   font-weight: 800;
                   color: $primary-color;
              }
              .stat-subtext{
                     color: $header-color;
                     font-size: 20px;
                     font-weight: 700;
              }
       }
  }
}