.plans {
    margin: 25px 25px 0 0;
    overflow-y: auto;
    height: calc(100vh - 92px);

    .ledger-details {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: space-around;
            margin-right: 25px;
        }
        .balance {
            width: 45%;
            padding: 0px 15px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid white;
            @media screen and (max-width: 1024px) {
                width: 90%;
            }
            .bal-title {
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                color: #454B69;
            }
            .border {
                margin: -15px 0 20px 0;
                border: 1px solid #EBEBEB;
            }
            .bal-value {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 25px;
                color: #00CDAC;
                display: flex;
                justify-content: space-between;
                padding-right: 15px;
            }
            p{
                .span1 {
                    text-decoration: underline;
                    font-weight: 700;
                    cursor: pointer;
                }
                .span2 {
                    font-weight: 400;
                }
                font-style: normal;
                font-size: 14px;
                line-height: 25px;
                color: #3F3D56;
            }
        }
        .mpesa {
            width: 45%;
            padding: 0px 15px;
            margin-right: 25px;
            background: #FFFFFF;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            border: 1px solid white;
            @media screen and (max-width: 1024px) {
                margin-right: 0;
                margin-top: 30px;
                width: 90%;
                padding: 15px;
            }
            .mpesa-title {
                display: flex;
                justify-content: space-between;
                h5 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    color: #454B69;
                }
                .h-colored {
                    color: #00CDAC;
                }
            }
            .border {
                margin: -15px 0 20px 0;
                border: 1px solid #EBEBEB;
            }
            .mpesa-btn {
                button {
                    border: 1px solid #D9D5EC;
                    border-radius: 6px;
                    width: 100%;
                    padding: 10px 30px;
                    display: flex;
                    justify-content: space-between;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #3F3D56;
                    cursor: pointer;
                }
                button:hover {
                    background: #e1e0e6;
                }
            }
        }
    }
    .add-service-wrap {
        display: flex;
        justify-content: space-between;
        margin-right: 25px;
        .bread-crumb {
            .linkspan {
                color: #00CDAC;
                font-size: 14px;
                cursor: pointer;
            }
            .separator {
                color: #A2A4B9;
            }
        }
        .add-service {
            display: flex;
            button {
                display: flex;
                align-items: center;
                margin-left: 15px;
                
                .material-icons {
                    font-size: 15px;
                    padding-left: 3px;
                }
            }
        }
    }

    .plan-label {
        background: #FFFFFF;
        border: 1px solid rgba(162, 164, 185, 0.78);
        border-radius: 16px;
        display: flex;
        padding-right: 100px;
        margin: 20px 25px 0 0;
        justify-content: space-between;
        @media screen and (max-width: 1024px) {
            padding-right: 0;
        }
        .sects {
            width: 48%;
            padding: 0 20px;
            h4 {
                font-style: normal;
                font-weight: 600;
                font-size: 19px;
                line-height: 0px;
                color: #A2A4B9;
            }
            p {
                font-style: normal;
                font-weight: 300;
                font-size: 15px;
                line-height: 29px;
                color: #00CDAC;
            }
        }
    }

    .title {
        border-bottom: 1px solid #C6C2DE;
        margin: 20px 25px 0 0;
        span {
            font-style: normal;
            font-weight: bold;
            font-size: 13px;
            line-height: 18px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            color: #3F3D56;
        }
    }

    .wrap {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        margin: 30px 25px 20px 0;
        padding-top: 20px;
        .table-search {
            display: flex;
            align-items: center;
            padding: 0 15px;
            justify-content: space-around;
            .right {
                width: 50%;
                display: flex;
                justify-content: flex-end;
                padding-right: 25px;
                @media screen and (max-width: 1024px) {
                    width: 0%;
                }
            }
            .left {
                width: 50%;
                display: flex;
                position: relative;
                @media screen and (max-width: 1024px) {
                    width: 100%;
                }
                .view-drop {
                    visibility: visible;
                }
                .hide-drop {
                    visibility: hidden;
                }
                .left-dropdown {
                    border: 1px solid #EFB7B9;
                    background-color: white;
                    position: absolute !important;
                    top: 39px;
                    width: 72%;
                    left: 35px;
                    border-radius: 6px;
                    p {
                        color: #71717A;
                        line-height: 10px;
                        margin: 0;
                        padding: 10px 7px;
                        display: flex;
                        .span1 {
                            padding: 7px;
                        }
                    }
                    p:hover {
                        background-color: #00CDAC;
                        cursor: pointer;
                        color: white;
                    }
                }
                .filter-table {
                    cursor: pointer;
                    border: 1px solid #EFB7B9;
                    box-sizing: border-box;
                    border-radius: 6px;
                    width: 18%;
                    display: flex;
                    align-items: center;
                    height: 37px;
                    justify-content: space-evenly;
                    color: #25213B;
                    font-size: 13px;
                    @media screen and (max-width: 1024px) {
                        width: 100px;
                    }
                    .material-icons {
                        color: #00CDAC;
                    }
                }
                .search-input {
                    width: 70%;
                    margin-left: 35px;
                    input {
                        width: 100%;
                        height: 33px;
                        background: #F4F2FF;
                        border-radius: 6px;
                        border: 1px solid #F4F2FF;
                        padding-left: 10px;
                    }
                    input:focus {
                        border: 1px solid #EFB7B9;
                        outline: none !important;
                    }
                }
            }
        }

        .tables {
            margin-top: 15px;
            width: 100%;
            overflow-y: hidden;
            // position: relative;
            // .table {
            //     // position: relative;
            // }
            .table-header    {
                background: #F4F2FF;
                text-transform: uppercase;
                font-style: normal;
                font-weight: 600;
                line-height: 15px;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: #3F3D56;
                .cell {
                    font-size: 12px;
                }
            }
            .pagination {
                background: #F4F2FF;
            }
            .clickable {
                color: #00CDAC;
                cursor: pointer;
            }
            .edit {
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                text-decoration-line: underline;
                color: #00CDAC;
                cursor: pointer;
            }
            .activate {
                display: flex;
                justify-content: space-around;
                .act {
                    background: #70D8A0;
                }
                .act:hover {
                    background: rgba(112, 216, 160, .7); 

                }
                .deact {
                    background: #FF6E37;
                }
                .deact:hover {
                    background: rgba(255, 110, 55, .7);
                }

                button {
                    cursor: pointer;
                    width: 95px;
                    display: flex;
                    align-items: center;
                    border-radius: 10px;
                    border: none;
                    color: white;
                    padding: 3px 5px;
                    .material-icons {
                        font-size: 11px;
                        padding-right: 5px;
                    }
                }
            }
        }
    }
    .plan-details-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 0;
        margin-bottom: 30px;
        @media screen and (max-width: 1024px) {
            flex-wrap: wrap;
            justify-content: space-around;
            margin-right: 25px;
        }
    }
    .plan-details:nth-child(2) {
        margin-right: 25px;
        @media screen and (max-width: 1024px) {
            margin-right: 0;
            margin-top: 30px;
            width: 90%;
            padding: 15px;
        }
    }
    .plan-details {
        width: 45%;
        padding: 0 15px;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        border: 1px solid white;
        @media screen and (max-width: 1024px) {
            width: 90%;
        }
        .plan-title {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            color: #454B69;
        }
        .border {
            margin: -15px 0 20px 0;
            border: 1px solid #EBEBEB;
        }
        .details {
            font-style: normal;
            width: 100%;
            p {
                display: flex;
                justify-content: space-between;
                padding-right: 10%;
                line-height: 20px;
                font-size: 15px;
            }
            .span1 {
                color: #3F3D56;
                font-weight: 600;
            }
            .span2 {
                color: #A2A4B9;
                font-weight: 500;
                width: 50%;
            }
        }
    }
}

.btn-1 {
    padding: 5px 15px;
}
.btn-2 {
    padding: 5px 10px;
}
.button-action {
    background: #00CDAC;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: white;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    border: none !important;
    cursor: pointer;
}

.button-action:hover {
    background: #0d8873;
}

.button-action-inverse {
    background: inherit;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: #00CDAC;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    border: 1px solid #00CDAC;
    cursor: pointer;
}

.button-action-inverse:hover {
    background: rgba(39, 56, 52, .2);
}

.button-action-misc {
    background: inherit;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    color: #00CDAC;
    font-style: normal;
    font-weight: 500;
    line-height: 23px;
    border: 1px solid #00CDAC;
    cursor: pointer;
}

.button-action-misc:hover {
    background: rgba(39, 56, 52, .2);
}

.dimension-1 {
    width: 400px;
    @media screen and (max-width: 1024px) {
        width: 80%;
    }
}

.dimension-2 {
    width: 450px;
    @media screen and (max-width: 1024px) {
        width: 80%;
    }
}

.add-plan {
    box-shadow: 0 0 0 50vmax rgba(239, 183, 185, 0.4);
    position: relative;
    .title {
        text-align: center;
        span {
            font-weight: bold;
            font-size: 28px;
            color: #71717A;
        }
    }
    .title-csv {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #A6A6A6;
    }
    .upload-content {
        #upload {
            opacity: 0;
            position: absolute;
            z-index: -1;
            visibility: hidden;
        }
        .plan-id-input {
            margin: 10px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            label {
                color: #A6A6A6;
                font-style: normal;
                font-weight: bold;
                font-size: 15px;
                padding-right: 20px;
            }
            select:focus {
                outline: none !important;
            }
            select {
                cursor: pointer;
                background: #F4F4F5;
                border: 1px solid #F4F4F5;
                box-sizing: border-box;
                border-radius: 4px;
                height: 30px;
                width: 100%;
                color: rgba(0, 0, 0, 0.6);
            }
            .input-error {
                background: rgba(255, 0, 0, .2);
                color: red;
                border: 1px solid red;
            }
    
            .input-error::placeholder {
                color:  red;
            }
            .wrap-select {
                width: 60%;
            }
            small {
                display: block;
                color: red;
                font-size: 12px;
                width: 100%;
            }
            .m-2 {
                margin: 5px 0;
            }
        }
        .sample-csv{
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            span {
                font-size: 13px;
                color: #A6A6A6;
            }
            a {
                color: 00CDAC;
            }
            .sample-btn {
                color: #00CDAC;
                font-size: 14px;
            }
            .sample-icon {
                color: #00CDAC;
                vertical-align: middle;
                font-size: 18px;
            }
        }
        .upload-label {
            margin-top: 5px;
            border: 1px dashed #00CDAC;
            padding: 25px 0;
            cursor: pointer;
            display: flex;
            justify-content: space-around;
            align-items: center;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            span {
                display: flex;
            }
            .material-icons {
                color: #A6A6A6;
                display: flex;
                justify-content: space-around;
                font-size: 70px;
            }
            .description {
                color: #A6A6A6;
                font-weight: 700;
                font-size: 14px;
                text-align: center;
                word-spacing: 3px;
                .colored {
                    color: #00CDAC;
                    display: inline;
                }
            }
            .placeholder {
                color: #A6A6A6;
                font-weight: 300;
                font-size: 14px;
                text-align: center;
            }
            .loader {
                position: absolute;
                opacity: .7;
            }
            .faded {
                position: absolute;
                width: 110px;
                height: 55px;
                top: 55px;
                border-radius: 0 0 150px 150px;
                text-align: center;
                color: white;
                display: flex;
                justify-content: space-around;
                align-items: center;
                background: rgba(0, 205, 172, .8);
                font-size: 12px;
                font-weight: 600;
                color: white;
            }
        }
        .upload-success {
            margin-top: 5px;
            .image-div {
                display: flex;
                justify-content: space-around;
                img {
                    width: 85%;
                }
            }
            .confirmation-text {
                p {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    color: #A6A6A6;
                    text-align: center;
                }
            }
        }
    }   
    .progress {
        margin-top: 30px;
        border-bottom: 2px solid #E8EDFF;
        transform: rotate(0.13deg);
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 3px 5px;
        .material-icons {
            color: #71717A;
            font-size: 32px;
            margin-right: 10px;
        }
    } 
    .body {
        margin: 10px 0;
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-right: 25px;
        }
        .auto {
            display: flex;
            align-items: center;
            @media screen and (max-width: 1024px) {
                flex-wrap: wrap;
            }
        }
        label {
            color: #71717A;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            padding-right: 20px;
        }
        select {
            cursor: pointer;
        }
        select:focus {
            outline: none !important;
        }
        input, select {
            background: #F4F4F5;
            border: 1px solid #F4F4F5;
            box-sizing: border-box;
            border-radius: 4px;
            height: 30px;
            width: 240px;
            margin-bottom: 10px;
            color: rgba(0, 0, 0, 0.6);
        }
        .input-error {
            background: rgba(255, 0, 0, .2);
            color: red;
            border: 1px solid red;
        }

        .input-error::placeholder {
            color:  red;
        }
        small {
            display: block;
            color: red;
            font-size: 12px;
        }
        .m-1 {
            margin: 0 0 5px 94px;
        }
        .m-2 {
            margin: 0 0 5px 138px;
        }
        .protate-wrap {
            display: flex;
            align-items: center;
            @media screen and (max-width: 1024px) {
                flex-wrap: wrap;
            }
            .checkbox {
                .protate {
                    width: 230px;
                }
            }
        }
        input:focus {
            outline: none !important;
            border: 1px solid #00CDAC;
        }
    }
    .title-mpesa {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 28px;
        color: #71717A;
        text-align: center;
    }
    .status-section {
        span {
            text-align: center;
            display: block;
            padding: 10px 0 10px 0;
            font-style: normal;
            font-weight: 400;
            color: #71717A;
        }
        img {
            width: 95%;
            text-align: center;
            display: block;
            margin: 0 auto;
        }
        p {
            display: block;
            text-align: center;
            font-style: normal;
            color: #71717A;
            font-size: 17px;
            margin-top: 30px;
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            button {
                border-radius: 9px;
                padding: 9px 30px;
                cursor: pointer;
                border: none !important;
            }
            .btns1 {
                background: #00CDAC;
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
                border-radius: 9px;
                color: white;
            }
            .btns1:hover {
                background: #0b8b76
            }
            .btns2 {
                background: #FFFFFF;
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
                border-radius: 9px;
                color: #3F3D56;
            }
            .btns2:hover {
                background: #EAEAEA;
            }
        }
    }
    .mpesa-body {
        margin: 30px 0;
        label {
            color: #71717A;
            font-style: normal;
            font-weight: bold;
            font-size: 15px;
            display: flex;
            justify-content: space-around;
            margin-bottom: 10px;
        }
        input {
            background: #F4F4F5;
            border: 1px solid #F4F4F5;
            box-sizing: border-box;
            border-radius: 4px;
            height: 45px;
            width: 100%;
            color: rgba(0, 0, 0, 0.6);
            padding-left: 10px;
            font-size: 17px;
            text-align: center;
        }
        input:focus {
            outline: none !important;
            border: 1px solid #00CDAC;
        }
        input::placeholder {
            text-align: center;
        }
        .input-error {
            background: rgba(255, 0, 0, .2);
            color: red;
            border: 1px solid red;
        }

        .input-error::placeholder {
            color:  red;
        }
        small {
            color: red;
            font-size: 12px;
            border: none !important;
        }
        .disclaimer {
            margin-top: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            small {
                color: #00CDAC;
                width: 100%;
                text-align: center;
                padding-bottom: 5px;
            }
        }
        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 35px;
            .btn {
                border-radius: 9px;
                height: 35px;
                width: 44%;
                font-size: 15px;
                letter-spacing: 0.2px;
                border: none !important;
                display: flex;
                align-items: center;
                justify-content: space-around;
                cursor: pointer;
            }
            .btn1 {
                color: white;
                background: #00CDAC;
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
            }
            .btn1:hover {
                background: #0c917a;
            }
            .btn2 {
                color: #3F3D56;
                background: #FFFFFF;
                box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.07);
            }
            .btn2:hover {
                background: #D9D5EC;
            }
        }
    }
    .footer {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-top: 20px;
    }
}
.add-plan:focus {
    outline: none !important;
}