// admin dashboard also uses the same layout
.client-dashboard {
    background: #E5E5E5;
    height: calc(100vh - 62px);
    padding: 20px;
    .wrapper {
        background: #FFFFFF;
        height: 95%;
        overflow-y: auto;
        .loader {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: 50%;
            img {
                height: 70px;
                width: 70px;
            }
        }
        .h4-div {
            border-bottom: 1px solid #d0d0d0;
            height: 17px;
            padding-left: 10px;
            margin-bottom: 20px;
        }
        p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 0;
            color: #454B69;
        }
        .companies {
            display: flex;
            flex-wrap: wrap;
            .card {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                align-items: center;
                padding: 15px 20px;
                margin: 0 20px 30px 10px;
                cursor: pointer;
                .typo {
                    flex-basis: 100%;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    letter-spacing: 0.1px;
                    color: #3F3D56;
                    display: flex;
                    justify-content: space-around;
                    width: 30px;
                    text-align: center;
                    // box
                    background: #FFFFFF;
                    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);
                    border-radius: 4px;
                    margin: 15px 0 5px 0;
                    padding: 8px 0;
                }
            }
            img {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                display: flex;
                align-items: center;
            }
            .img-alt {
                width: 90px;
                height: 90px;
                border-radius: 50%;
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin: 0;
                font-weight: 500;
                font-size: 25px;
            }
            .red {
                background-color: firebrick;
            }
            .orange {
                background-color: orange;
            }
            .yellow {
                background-color: rgb(141, 141, 46);
            }
            .green {
                background-color: rgb(82, 190, 82);
            }
            .blue {
                background-color: rgb(102, 102, 206);
            }
            .purple {
                background-color: purple;
            }
        }
    }
}