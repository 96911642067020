@import "./defaults.scss";

.admin-company-dashboard {
  // background: #e5e5e5;
  height: max-content;
  padding: 20px;
  overflow-y: auto;
  .wrapper {
    height: 100%;
    overflow-y: auto;
    .h4-div {
      border-bottom: 1px solid #d0d0d0;
      height: 17px;
      padding-left: 10px;
      margin-bottom: 20px;
    }
    .statHeader {
      font-size: 1.3rem;
      font-weight: 600;
      color: $head-color1;
    }
    .pstat-info {
      bottom: 0;
      display: flex;
      width: 35%;
      justify-items: flex-end;
      justify-content: space-between;
      gap: 6rem;
      align-items: flex-end;
      height: max-content;
      p {
        font-size: 1.5rem;
        color: $primary-color;
        font-weight: 300;
      }
    }
    p {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 0;
      color: #454b69;
    }
    .stats {
      display: flex;
      justify-content: space-between;
      .box {
        background: white;
        border-radius: 8px;
        width: 32%;
        height: 150px;
        .icon {
          height: 50%;
          display: flex;
          justify-content: flex-end;
          span {
            background-color: #00cdac;
            width: 60px;
            border-radius: 0 8px 0 40%;
            text-align: center;
            padding-top: 15px;
            color: white;
            font-size: 30px;
          }
        }
        .details {
          padding-left: 20px;
          .p1 {
            line-height: 0;
            font-size: 28px;
            font-weight: 600;
            color: #00cdac;
          }
          p {
            line-height: 0;
            color: #3f3d56;
            font-size: 17px;
            font-weight: 700;
          }
        }
      }
    }
    .table-wrap {
      background: white;
      border-radius: 4px;
      margin-top: 30px;
      .header-section {
        padding-top: 10px;
        .l-div {
          padding-left: 10px;
          margin-bottom: 30px;
          display: flex;
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 0;
            cursor: pointer;
          }
          .view {
            width: 7%;
            height: 25px;
            padding-left: 5px;
          }
          .active {
            border-bottom: 1px solid #00cdac;
            color: #00cdac;
          }
          .inactive {
            border-bottom: 1px solid #d0d0d0;
            color: #454b69;
          }
          .space {
            width: 79%;
            height: 25px;
            border-bottom: 1px solid #d0d0d0;
          }
        }
        .t-div {
          border-bottom: 1px solid #d0d0d0;
          height: 10px;
          padding-left: 10px;
          margin-bottom: 20px;
          p {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 0;
            color: #454b69;
          }
        }
        .table-search {
          margin: 0 0 15px 15px;

          .view-drop {
            visibility: visible;
          }
          .hide-drop {
            visibility: hidden;
          }
          .left {
            width: 50%;
            display: flex;
            position: relative;
            @media screen and (max-width: 1024px) {
              width: 100%;
            }
            .left-dropdown {
              border: 1px solid #efb7b9;
              background-color: white;
              position: absolute !important;
              top: 39px;
              width: 72%;
              left: 35px;
              border-radius: 6px;
              p {
                color: #71717a;
                line-height: 10px;
                margin: 0;
                padding: 10px 7px;
                display: flex;
                .span1 {
                  padding: 7px;
                }
              }
              p:hover {
                background-color: #00cdac;
                cursor: pointer;
                color: white;
              }
            }
          }
          .custom {
            background: #f4f2ff;
            width: 35%;
            border-radius: 6px;
            height: 40px;
            display: flex;
            align-items: center;
            span {
              width: 10%;
              display: flex;
              justify-content: space-around;
              align-items: center;
              color: #00cdac;
            }
            input {
              width: 90%;
              height: 40px;
              border: none !important;
              background: #f4f2ff;
              border-radius: 0 6px 6px 0;
              padding: 0 5px;
              font-size: 14px;
              color: #181616;
              outline: none !important;
            }
          }
        }
      }
      .tables {
        margin-top: 15px;
        width: 100%;
        overflow-y: hidden;
        .table-header {
          background: #f4f2ff;
          text-transform: uppercase;
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: #3f3d56;
          .cell {
            font-size: 12px;
          }
        }
        .pagination {
          background: #f4f2ff;
        }
        .clickable {
          color: #00cdac;
          cursor: pointer;
        }
        .edit {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          text-decoration-line: underline;
          color: #00cdac;
          cursor: pointer;
        }
        .activate {
          display: flex;
          justify-content: space-around;
          .act {
            background: #70d8a0;
          }
          .act:hover {
            background: rgba(112, 216, 160, 0.7);
          }
          .deact {
            background: #ff6e37;
          }
          .deact:hover {
            background: rgba(255, 110, 55, 0.7);
          }

          button {
            cursor: pointer;
            width: 95px;
            display: flex;
            align-items: center;
            border-radius: 10px;
            border: none;
            color: white;
            padding: 3px 5px;
            .material-icons {
              font-size: 11px;
              padding-right: 5px;
            }
          }
        }
      }
    }
  }
}
