$primary-color: #00cdac;
$header-color: #3f3d56;
$head-color1: #a2a4b9;

@mixin header-style {
  color: $header-color;
  font-size: 20px;
  font-weight: 700;
  padding: 10px;
}

.wrap {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin: 30px 25px 20px 0;
  padding-top: 20px;
  .table-search {
    display: flex;
    align-items: center;
    padding: 0 15px;
    justify-content: space-around;
    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
      padding-right: 25px;
      @media screen and (max-width: 1024px) {
        width: 0%;
      }
    }
    .left {
      width: 50%;
      display: flex;
      position: relative;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      .view-drop {
        visibility: visible;
      }
      .hide-drop {
        visibility: hidden;
      }
      .left-dropdown {
        border: 1px solid #efb7b9;
        background-color: white;
        position: absolute !important;
        top: 39px;
        width: 72%;
        left: 35px;
        border-radius: 6px;
        p {
          color: #71717a;
          line-height: 10px;
          margin: 0;
          padding: 10px 7px;
          display: flex;
          .span1 {
            padding: 7px;
          }
        }
        p:hover {
          background-color: #00cdac;
          cursor: pointer;
          color: white;
        }
      }
      .filter-table {
        cursor: pointer;
        border: 1px solid #efb7b9;
        box-sizing: border-box;
        border-radius: 6px;
        width: 18%;
        display: flex;
        align-items: center;
        height: 37px;
        justify-content: space-evenly;
        color: #25213b;
        font-size: 13px;
        @media screen and (max-width: 1024px) {
          width: 100px;
        }
        .material-icons {
          color: #00cdac;
        }
      }
      .search-input {
        width: 70%;
        margin-left: 35px;
        input {
          width: 100%;
          height: 33px;
          background: #f4f2ff;
          border-radius: 6px;
          border: 1px solid #f4f2ff;
          padding-left: 10px;
        }
        input:focus {
          border: 1px solid #efb7b9;
          outline: none !important;
        }
      }
    }
  }

  .tables {
    margin-top: 15px;
    width: 100%;
    overflow-y: hidden;
    // position: relative;
    // .table {
    //     // position: relative;
    // }
    .table-header {
      background: #f4f2ff;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #3f3d56;
      .cell {
        font-size: 12px;
      }
    }
    .pagination {
      background: #f4f2ff;
    }
    .clickable {
      color: #00cdac;
      cursor: pointer;
    }
    .edit {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      text-decoration-line: underline;
      color: #00cdac;
      cursor: pointer;
    }
    .activate {
      display: flex;
      justify-content: space-around;
      .act {
        background: #70d8a0;
      }
      .act:hover {
        background: rgba(112, 216, 160, 0.7);
      }
      .deact {
        background: #ff6e37;
      }
      .deact:hover {
        background: rgba(255, 110, 55, 0.7);
      }

      button {
        cursor: pointer;
        width: 95px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        border: none;
        color: white;
        padding: 3px 5px;
        .material-icons {
          font-size: 11px;
          padding-right: 5px;
        }
      }
    }
  }
}
