.dashboard-cover {
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    width: 100%;
    .sidebar {
        width: 16%;
        background: #FFFFFF;
        box-shadow: 10px 3px 15px rgba(0, 0, 0, 0.05);
        height: 100vh;
        padding-left: 15px;
        .icon {
            padding-right: 10px;
        }

        .header {
            height: 30%;
            .logo-wrap {
                img {
                    width: 100px;
                    height: 100px;
                    margin-top: 15px;
                    cursor: pointer;
                }
            }
        }

        .links {
            height: 50%;
            button {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                background: inherit;
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                border: none;
            }
            .active {
                color: #00CDAC;
                border-right: 3px solid #00CDAC;
            }
            .inactive {
                color: #A2A4B9;
            }
        }

        .footer {
            button {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #A2A4B9;
                border: none !important;
                background: inherit;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            button:hover {
                color: grey;
            }
        }
    }

    .content {
        width: 84%;
        height: 100vh;
        background-color: #F4F5F9;
        overflow-y: auto;
        @media screen and (max-width: 1024px) {
            width: 100%;
            background-color: #F4F5F9;

        }
        .appbar {
            background: #FFFFFF;
            box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.1);
            border-radius: 0px;
            display: flex;
            align-items: center;
            height: 65px;
            width: 100%;
            .left {
                width: 50%;
                display: flex;
                justify-content: space-around;
                .border-btn {
                    color: #00CDAC;
                    margin-left: -23px;
                }
                .search {
                    input {
                        background: #FFFFFF;
                        box-shadow: 3px 5px 15px rgba(0, 0, 0, 0.2);
                        border: none !important;
                        border-radius: 9px;
                        height: 30px;
                        width: 300px;
                        padding-left: 10px;
                    }
                    input:focus {
                        outline: none !important;
                        box-shadow: 3px 5px 15px rgba(78, 100, 77, 0.7);
                    }
                }
            }
            .right {
                display: flex;
                width: 50%;
                justify-content: flex-end;
                padding-right: 30px;
                position: relative;

                .notification {
                    margin-right: 40px;
                }
                .settings {
                    background: rgba(0, 205, 172, .04);
                    border-radius: 8px;
                    width: 50%;
                    height: 45px;
                    display: flex;
                    cursor: pointer;

                    .icon {
                        padding: 0 5px;
                        margin-right: 5px;
                        display: flex;
                        align-items: center;
                        img {
                            width: 38px;
                            height: 38px;
                        }
                    }
                    .client-abb {
                        border-radius: 50%;
                        border: 1px solid #A2A4B9;
                        width: 38px;
                        height: 38px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        color: white;
                        background: #A2A4B9;
                    }
                    .details {
                        display: flex;
                        flex-wrap: wrap;
                        color: #181a18;
                        padding-top: 5px;
                        .title {
                            font-size: 14px;
                            font-weight: bold;
                        }
                        .desc {
                            font-size: 12px;
                        }
                    }
                    .more {
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-start;
                        width: 30%;
                        .btn {
                            color: #181a18;
                        }
                    }
                }
                .menu-section {
                    position: absolute;
                    // top: 46px;
                    background: white;
                    box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.05);
                    border-radius: 8px;
                    width: 47.5%;
                    height: 45px;
                    display: flex;
                    align-items: center;
                    p {
                        padding: 0 10px;
                        width: 100%;
                        height: 35px;
                        font-size: 14px;
                        cursor: pointer;
                        font-weight: 500;
                        color: #A2A4B9;
                        display: flex;
                        align-items: center;
                        span {
                            padding: 0 5px;
                        }
                    }
                }
            }
        }
    }
}
.dash-modal {
    .sidebar {
        background: #FFFFFF;
        box-shadow: 10px 3px 15px rgba(0, 0, 0, 0.05);
        height: 100vh;
        padding-left: 15px;
        .icon {
            padding-right: 10px;
        }

        .header {
            height: 20%;
            height: 65px;
            margin-bottom: 40px;
            display: flex;
            align-items: center;
            .mn-btn {
                padding: 0;
                margin-left: -14px;
                color: #00CDAC;
            }
            h3 {
                color: #00CDAC;
                padding: 0 7px;
                cursor: pointer;
            }
        }

        .links {
            height: 65%;
            button {
                font-style: normal;
                font-weight: 400;
                font-size: 15px;
                background: inherit;
                width: 100%;
                display: flex;
                align-items: center;
                margin-bottom: 15px;
                cursor: pointer;
                border: none;
            }
            .active {
                color: #00CDAC;
                border-right: 3px solid #00CDAC;
            }
            .inactive {
                color: #A2A4B9;
            }
        }

        .footer {
            button {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: #A2A4B9;
                border: none !important;
                background: inherit;
                width: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            button:hover {
                color: grey;
            }
        }
    }
}